@charset "UTF-8";
@keyframes LoginMove {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

* {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }

body {
  font-family: 'Metropolis-ExtraLight', sans-serif;
  color: #333333; }

a {
  text-decoration: none; }

::selection {
  background-color: #f15a24;
  color: white; }

.page-container {
  background-color: #f5f6fa;
  overflow: hidden; }

.content {
  margin-left: 8.5rem;
  margin-top: 11rem;
  min-height: 100vh;
  padding: 0 2.5rem 2.5rem;
  max-width: 100%; }

::-webkit-scrollbar {
  width: 0.4rem; }

/* Místo kde posuvník jezdí*/
::-webkit-scrollbar-track {
  background: #cccccc;
  border-radius: 10px; }

/* Posuvník */
::-webkit-scrollbar-thumb {
  background: #83888f;
  border-radius: 10px; }

input::-webkit-credentials-auto-fill-button {
  visibility: hidden; }

.container {
  padding: 2rem;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 3rem;
  box-shadow: 0.5rem 0.5rem 2rem rgba(0, 0, 0, 0.1); }

.container-2 {
  position: relative;
  width: 100%;
  height: 100%; }

.container-1 {
  padding: 1rem;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 3rem;
  box-shadow: 0.5rem 0.5rem 2rem rgba(0, 0, 0, 0.1); }

.menu-item {
  display: flex;
  align-items: center; }
  .menu-item p {
    font-size: 1.3rem;
    margin: 0 3rem 0 1rem;
    text-transform: uppercase; }

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 3.6rem;
  height: 2.04rem; }

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccccc;
  border-radius: 2.04rem;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

.slider:before {
  position: absolute;
  content: '';
  height: 1.56rem;
  width: 1.56rem;
  left: 0.24rem;
  bottom: 0.24rem;
  background-color: white;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

input:checked + .slider {
  background-color: #f7931e; }

input:focus + .slider {
  box-shadow: 0 0 1px #f7931e; }

input:checked + .slider:before {
  -webkit-transform: translateX(1.56rem);
  -ms-transform: translateX(1.56rem);
  transform: translateX(1.56rem); }

.asc {
  justify-self: center; }

.authors-table-row {
  grid-template-columns: 0.4fr 0.8fr 1fr 0.8fr 0.2fr repeat(2, 1fr) 0.1fr; }

.series-table-row {
  grid-template-columns: 0.4fr 1fr 0.5fr repeat(5, 1fr) 0.1fr; }

.characters-table-row {
  grid-template-columns: 0.4fr repeat(7, 1fr) 0.1fr; }

.organizations-table-row {
  grid-template-columns: 0.4fr 1.5fr repeat(6, 1fr) 0.1fr; }

.earths-table-row {
  grid-template-columns: 0.4fr 0.6fr repeat(4, 1fr) 0.1fr; }

.publishings-table-row {
  grid-template-columns: 0.4fr 1.2fr 0.5fr repeat(5, 1fr) 0.1fr; }

.books-table-row {
  grid-template-columns: 0.4fr 0.9fr repeat(4, 1fr) 0.1fr; }

.events-table-row {
  grid-template-columns: 0.4fr 0.9fr repeat(4, 1fr) 0.1fr; }

.storyArcs-table-row {
  grid-template-columns: 0.4fr 0.6fr repeat(4, 1fr) 0.1fr; }

.bookSeries-table-row {
  grid-template-columns: 0.4fr 0.9fr repeat(4, 1fr) 0.1fr; }

.editor-class {
  font-family: 'Metropolis-Light', sans-serif;
  font-size: 1.6rem;
  color: #777777;
  line-height: 0.6rem; }

.editor-box {
  display: flex;
  grid-column: 2/4;
  grid-row: 3 span;
  height: 100%; }

.rsw-editor {
  border: none !important;
  background-color: #f5f5f5;
  border-radius: 1.7rem !important;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  height: 100%;
  overflow: hidden;
  font-size: 1.6rem !important;
  color: #333333 !important;
  overflow-y: scroll !important;
  text-align: justify !important;
  font-family: Helvetica, sans-serif;
  width: 100%;
  padding: 1rem; }

.rsw-editor::-webkit-scrollbar {
  display: none; }

.rsw-ce {
  flex: 1 0 auto;
  outline: none;
  padding: 0.5rem; }

.rsw-ce[contentEditable='true']:empty:not(:focus):before {
  content: attr(placeholder);
  color: grey; }

.rsw-html {
  background: transparent;
  border: none;
  font-family: monospace, 'Courier New'; }

.rsw-separator {
  align-self: stretch;
  border-right: 1px solid #ddd;
  margin-bottom: 2rem !important;
  display: flex; }

.rsw-dd {
  box-sizing: border-box;
  outline: none; }

.rsw-btn {
  background: transparent;
  border: 0;
  cursor: pointer;
  color: #777777 !important;
  font-size: 2.2rem !important;
  height: 4rem !important;
  outline: none;
  padding: 0;
  width: 4rem !important;
  transition: all 0.3s !important; }

.rsw-btn:hover {
  background: #eaeaea; }

.rsw-btn[data-active='true'] {
  background: #e0e0e0; }

.rsw-toolbar {
  align-items: center;
  background-color: transparent !important;
  border-bottom: 1px solid #ddd;
  display: flex;
  margin-bottom: 1rem !important; }

.entity-label {
  display: flex;
  position: absolute;
  top: -3rem;
  left: 0rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Metropolis-Medium', sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #cccccc;
  letter-spacing: 0.2rem; }

.entity-label:before,
.entity-label:after {
  content: '';
  border-top: 2px solid;
  margin: 0 20px 0 -20px;
  flex: 1 0 20px;
  color: #ebecee; }

.entity-label:after {
  margin: 0 -20px 0 20px; }

.volume__box {
  margin-top: 2rem; }

.volume-box-box {
  position: relative; }

.volume__item__header {
  width: 100%;
  height: 8rem;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  padding: 0 2rem 0 2rem; }
  .volume__item__header-open {
    border-radius: 1rem 1rem 0 0; }
  .volume__item__header-closed {
    border-radius: 1rem; }

.volume__item__area {
  width: 100%;
  border-radius: 0 0 1rem 1rem; }

.volume__issues-label {
  font-family: 'Metropolis-Medium', sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase; }

.volume__save {
  width: 3rem;
  height: 3rem;
  fill: #77dd77;
  cursor: pointer;
  transition: all 0.2s; }
  .volume__save:hover {
    transform: scale(110%); }
  .volume__save-btn {
    background-color: transparent;
    border: none; }

.volume-button-area {
  display: flex;
  margin-left: auto;
  margin-right: 0.25rem; }

.volume__delete {
  width: 2rem;
  height: 2rem;
  color: #eeeeee; }
  .volume__delete-btn {
    width: 3rem;
    height: 3rem;
    background-color: #f7655e;
    fill: #eeeeee;
    border: none;
    border-radius: 100%;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center; }
    .volume__delete-btn:hover {
      transform: scale(110%); }

.volume__minimize {
  width: 2rem;
  height: 2rem;
  color: #eeeeee; }
  .volume__minimize-btn {
    width: 3rem;
    height: 3rem;
    background-color: #56bae4;
    fill: #eeeeee;
    border: none;
    border-radius: 100%;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center; }
    .volume__minimize-btn:hover {
      transform: scale(110%); }

.volume__title {
  font-family: 'Metropolis-Light', sans-serif;
  font-size: 1.4rem;
  color: #333333;
  background-color: white;
  border: none;
  width: 40rem;
  height: 60%;
  outline: none;
  overflow: hidden;
  padding: 1rem;
  border-radius: 0.6rem; }

.volume__container {
  position: relative; }

.btn-invisible {
  display: none;
  visibility: hidden; }

.btn-confirm {
  position: absolute;
  background: transparent;
  color: #50c878;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  right: 1rem; }
  .btn-confirm-1 {
    top: 0.7rem; }

.btn-add-alias {
  position: absolute;
  background: transparent;
  color: #56bae4;
  border: none;
  cursor: pointer;
  font-size: 2.5rem;
  right: 1rem;
  top: 1.8rem; }
  .btn-add-alias:hover {
    transform: perspective(1px) scale(1.1);
    color: #46aad4;
    transition: all 0.4s; }

.volume-table-row {
  grid-template-columns: 0.15fr 0.15fr 1fr 0.6fr 0.2fr 0.8fr 0.3fr 0.02fr; }

.volume-table-image {
  width: 3.3rem;
  cursor: pointer; }

.volume-table-item-text {
  font-family: 'Metropolis-Medium', sans-serif;
  font-size: 1.4rem;
  color: #333333; }

.volume-table-item-icon {
  font-size: 2rem;
  color: #333333;
  margin-right: 0.2rem; }

.volume-table-item-button {
  font-size: 2rem;
  color: #777777;
  margin-right: 0.2rem;
  cursor: pointer; }

.volume-table-percent-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: none; }

.add-volume-block {
  display: flex;
  justify-content: center;
  margin-top: 2rem; }
  .add-volume-block-btnarea {
    display: flex;
    width: 30%;
    justify-content: space-evenly; }

.add-volume-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  height: 5rem;
  border-radius: 0 0 1rem 1rem; }

.add-volume-btn {
  font-family: 'Metropolis-Medium', sans-serif;
  font-size: 3rem;
  color: white;
  background-color: #56bae4;
  width: 5rem;
  height: 5rem;
  border: none;
  border-radius: 5rem;
  cursor: pointer; }

.add-issue-btn {
  font-family: 'Metropolis-Medium', sans-serif;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  color: white;
  width: auto;
  padding: 1rem;
  height: 3rem;
  border: none;
  border-radius: 0.6rem;
  cursor: pointer;
  transition: all 0.4s; }
  .add-issue-btn-icon {
    width: 1.5rem;
    height: 1.5rem;
    color: white;
    margin-right: 0.2rem; }
  .add-issue-btn:disabled {
    opacity: 0.4;
    cursor: auto; }
  .add-issue-btn:not(:last-child) {
    margin-right: 1rem; }
  .add-issue-btn-blue {
    background-color: #56bae4; }
    .add-issue-btn-blue:hover {
      background-color: #3eabd9; }
  .add-issue-btn-green {
    background-color: #75cc93; }
  .add-issue-btn-purple {
    background-color: #ac96d4; }
  .add-issue-btn-red {
    background-color: #ed6861; }

.story__box {
  margin-top: 2rem; }

.story__item {
  display: flex;
  justify-content: space-evenly; }

.story-description {
  grid-column: 3/5;
  grid-row: 2 / end;
  height: 100%; }

.story-authors {
  grid-row: 2 / end;
  grid-column: 1 / 3;
  margin-bottom: 20rem; }
  .story-authors-line {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    background-color: #f5f5f5;
    border-radius: 1rem; }
    .story-authors-line-title {
      font-family: 'Metropolis-Medium', sans-serif;
      font-size: 1.4rem;
      color: #333333;
      text-transform: capitalize;
      flex-basis: 30%;
      padding: 2rem 1rem; }

.story-header {
  grid-column: 1 / 3; }

.story-del-button {
  grid-column: 4 / 5;
  justify-self: end; }

.story-reprint {
  grid-column: 3 / 4; }

.story-organization {
  grid-column: 1 / 3;
  height: 100%; }

.story-characters {
  grid-column: 1 / 3; }

.select-cont-flex {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem 0 1rem 0;
  width: 100%; }

.select-del-btn {
  position: absolute;
  right: 0.6rem;
  top: 1.7rem;
  background-color: #f5f5f5;
  border: none;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  color: #ff817a; }
  .select-del-btn:hover {
    transform: scale(1.05);
    transition: 0.3s;
    color: #ed6861;
    border: none; }

.img-small {
  width: 50px; }

.breadcrumbs {
  height: 3rem;
  margin-left: 2.5rem;
  font-family: 'Metropolis-Medium', sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-decoration: none;
  color: #83888f; }
  .breadcrumbs-arrow {
    margin: 0 0.6rem; }
  .breadcrumbs a {
    text-decoration: none;
    color: #83888f;
    transition: all 0.2s; }
    .breadcrumbs a:visited {
      color: #83888f; }
    .breadcrumbs a:hover {
      color: #f15a24; }

.table-footer {
  width: 100%;
  border-radius: 0 0 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #83888f;
  background-color: #f4f4fb;
  height: 5rem;
  border-radius: 0 0 1rem 1rem; }

.volume-table-image-2 {
  width: 2.8rem;
  cursor: pointer; }

.volume-table-image-x {
  width: 2.8rem;
  height: 2.8rem;
  cursor: pointer;
  fill: #cccccc; }

.bulk-edit-modal {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 50rem;
  height: 35rem;
  background-color: white;
  border-radius: 2rem;
  padding: 2rem; }
  .bulk-edit-modal-create {
    width: 50rem;
    height: 35rem; }
  .bulk-edit-modal-update {
    width: 50rem;
    height: 25rem; }
  .bulk-edit-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    height: 100vh;
    width: 100vw;
    z-index: 1000; }

.bulk-edit-heading {
  font-family: 'Metropolis-Medium', sans-serif;
  font-size: 1.7rem;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; }
  .bulk-edit-heading::after {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: 0;
    width: 100%;
    border-bottom: 2px solid #ebecee; }

.bulk-edit-icon {
  width: 3.5rem;
  height: 3.5rem;
  fill: #EA7427;
  cursor: pointer; }

.bulk-edit-create-issues {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.bulk-edit-date-row {
  display: flex;
  width: 100%; }

.bulk-edit-input-column {
  display: flex;
  flex-direction: column; }

.bulk-edit-date-column {
  display: flex;
  flex: 1;
  flex-direction: column; }
  .bulk-edit-date-column:not(:last-child) {
    margin-right: 3rem; }

.bulk-edit-input {
  font-family: 'Metropolis-Medium', sans-serif;
  font-size: 1.6rem;
  color: #333333;
  background-color: #ebecee;
  height: 4rem;
  width: 100%;
  border: none;
  border-radius: 4rem;
  padding-left: 2rem;
  padding-right: .8rem;
  outline: none; }
  .bulk-edit-input-label {
    font-family: 'Metropolis-Light', sans-serif;
    font-size: 1.2rem;
    color: #333333;
    margin-bottom: 1rem;
    margin-left: 1rem; }

.bulk-edit-btn {
  display: inline-block;
  font-family: 'Metropolis-Medium', sans-serif;
  font-size: 18px;
  border-radius: 10rem;
  background: #f58320;
  color: #f5f5f5;
  border: none;
  padding: 1.8rem 10rem;
  text-transform: uppercase;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
  cursor: pointer;
  text-align: center; }

.input-box-1--disabled {
  display: flex;
  position: relative;
  align-items: center;
  background-color: #fafafa;
  border: none;
  border-radius: 1.7rem;
  height: 100%;
  grid-row: span 2; }

.detail-form__main-3 {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: repeat(2, minmax(15rem, 1fr));
  align-items: top;
  grid-column-gap: 2rem;
  grid-row-gap: 1.5rem;
  grid-column: 2/5; }

.checkbox-box {
  display: flex;
  position: relative;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 1.7rem;
  height: 6rem;
  width: 100%; }

.checkbox-label {
  font-size: 1.4rem;
  color: #333333;
  padding-left: 1rem;
  font-family: 'Metropolis-Medium', sans-serif; }

.checkbox-countries {
  cursor: pointer;
  left: 1.5rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between; }

.checkbox-countries::after {
  cursor: pointer;
  left: 1.5rem;
  align-items: center;
  justify-content: space-between; }

.detail-form__nationality__country-row__item {
  width: 100%;
  margin-left: 1rem;
  position: relative; }
  .detail-form__nationality__country-row__item__check {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: -1.5rem;
    opacity: 0 !important;
    width: 1.6rem;
    height: 1.6rem;
    z-index: 100; }
    .detail-form__nationality__country-row__item__check:checked {
      opacity: 0 !important; }
  .detail-form__nationality__country-row__item:hover .detail-form__nationality__country-row__item__check {
    opacity: 0 !important; }

.connect-num {
  display: flex;
  position: absolute;
  z-index: 10;
  color: #333333;
  background-color: gainsboro;
  border-radius: 0.5rem;
  height: 2.8rem;
  width: 2.8rem;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  font-family: 'Metropolis-Medium', sans-serif; }

.circle-img-upload {
  display: flex;
  flex-direction: column;
  width: 8rem;
  height: 8rem;
  background-color: #f5f5f5;
  border-radius: 50%;
  z-index: 1;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 2.5rem 3rem 2.5rem;
  text-align: center; }

.alias-del-button {
  position: absolute;
  top: 1.5rem;
  right: 1rem; }

.assigned-characters-row {
  position: relative;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr 4rem;
  padding: 0 0 1rem 0;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem; }

.author-team-box {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; }

.author-team-row {
  position: relative;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 15rem 1fr 4rem;
  padding: 0 0 1rem 0;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem; }

/* FONT IMPORT */
@font-face {
  font-family: 'Metropolis-Black';
  src: url("/src/utils/fonts/Metropolis-Black.otf"); }

@font-face {
  font-family: 'Metropolis-Bold';
  src: url("/src/utils/fonts/Metropolis-Bold.otf"); }

@font-face {
  font-family: 'Metropolis-Medium';
  src: url("/src/utils/fonts/Metropolis-Medium.otf"); }

@font-face {
  font-family: 'Metropolis-Light';
  src: url("/src/utils/fonts/Metropolis-Light.otf"); }

@font-face {
  font-family: 'Metropolis-ExtraLight';
  src: url("/src/utils/fonts/Metropolis-ExtraLight.otf"); }

.p {
  font-family: 'Metropolis-ExtraLight', sans-serif;
  font-size: 1.6rem;
  color: #f5f5f5;
  line-height: 2rem; }
  .p-dark {
    color: #333333; }
  .p-date {
    line-height: 1.3;
    font-size: 1.1rem;
    color: #777777; }
  .p-logo {
    font-family: 'Metropolis-Light', sans-serif;
    color: #777777;
    font-size: 1.6rem; }

.alert-label {
  font-family: 'Metropolis-Light', sans-serif;
  font-size: 1.6rem;
  color: #f15a24; }

.heading-1 {
  font-family: 'Metropolis-Bold', sans-serif;
  font-size: 2.9rem;
  color: #f5f5f5; }

.heading-2 {
  font-family: 'Metropolis-Bold', sans-serif;
  font-size: 2.9rem; }
  .heading-2--light {
    color: #f5f5f5;
    text-align: left; }
  .heading-2--dark {
    color: #777777; }

.heading-3 {
  font-family: 'Metropolis-Medium', sans-serif;
  font-size: 1.3rem; }
  .heading-3--dark {
    color: #777777; }
  .heading-3--light {
    color: white; }

.heading-form {
  font-family: 'Metropolis-Medium', sans-serif;
  font-size: 2.5rem;
  color: #333333;
  align-items: center;
  justify-content: center; }

.bold {
  font-family: 'Metropolis-Medium', sans-serif; }

.pad-bottom-xs {
  padding-bottom: 1rem; }

.pad-bottom-s {
  padding-bottom: 2rem; }

.pad-bottom-m {
  padding-bottom: 4rem; }

.pad-right-s {
  padding-right: 2rem;
  padding-left: 2rem; }

.mar-bottom-xs {
  margin-bottom: 1rem !important; }

.mar-bottom-s {
  margin-bottom: 2rem !important; }

.mar-bottom-m {
  margin-bottom: 4.6rem !important; }

.mar-bottom-l {
  margin-bottom: 7.5rem !important; }

.mar-top-s {
  margin-top: 2rem !important; }

.mar-top-m {
  margin-top: 4rem !important; }

.mar-top-l {
  margin-top: 6rem !important; }

.mar-top-xl {
  margin-top: 8rem !important; }

.mar-left-xs {
  margin-left: .5rem !important; }

.mar-left-s {
  margin-left: 2rem !important; }

.mar-left-m {
  margin-left: 4rem !important; }

.mar-left-l {
  margin-left: 6rem !important; }

.mar-left-xl {
  margin-left: 8rem !important; }

.mar-right-xs {
  margin-right: 1rem !important; }

.mar-right-s {
  margin-right: 2rem !important; }

.mar-right-m {
  margin-right: 4rem !important; }

.mar-right-l {
  margin-right: 6rem !important; }

.mar-right-xl {
  margin-right: 8rem !important; }

.link-style {
  text-decoration: none; }

.center-self {
  align-self: center; }

.btn {
  font-family: 'Metropolis-Bold', sans-serif;
  text-transform: uppercase;
  color: white;
  border: none;
  outline: none;
  box-shadow: 0.5rem 0.5rem 2rem rgba(0, 0, 0, 0.2);
  border-radius: 6rem;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }
  .btn:hover {
    transform: scale(1.01) translateY(-1px);
    box-shadow: 0.5rem 0.5rem 2rem rgba(0, 0, 0, 0.3); }
  .btn:active {
    transform: translateY(1px);
    box-shadow: 0.5rem 0.5rem 2rem rgba(0, 0, 0, 0.2); }

.info-icon {
  height: 4rem;
  width: 4rem;
  color: orange;
  padding-right: 1rem;
  font-size: 4rem; }

.icon-btn {
  height: 2.5rem;
  width: 2.5rem; }

.button-icon {
  padding-right: 0.5rem;
  margin-top: -0.2rem;
  font-size: 2rem; }

.btn-del {
  background-image: linear-gradient(to right, #777777, #83888f);
  font-size: 1.2rem;
  padding: 1rem;
  width: 11rem; }

.btn-s {
  background-image: linear-gradient(to right, #f47521, #f58320);
  font-size: 1rem;
  padding: 1rem; }

.btn-s-blue {
  background-image: #56bae4;
  font-size: 1rem;
  padding: 1rem; }

.btn-m {
  background-image: linear-gradient(to right, #f47521, #f58320);
  font-size: 1.2rem;
  padding: 1.2rem; }

.btn-l {
  background-image: linear-gradient(to right, #f47521, #f58320);
  font-size: 1.2rem;
  padding: 1rem;
  width: 11rem; }

.btn-red {
  background-image: linear-gradient(to right, red, red); }

.btn-text {
  display: inline-block;
  font-family: 'Metropolis-Light', sans-serif;
  font-size: 1.6rem;
  text-decoration: none;
  color: #f5f5f5;
  padding: 1.5rem 4rem;
  display: inline-block;
  position: relative;
  border-radius: 10rem;
  border: 2px solid #f5f5f5;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  transition: transform 0.4s, box-shadow 0.4s;
  position: relative; }
  .btn-text:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); }

.btn-full {
  display: inline-block;
  font-family: 'Metropolis-Medium', sans-serif;
  font-size: 18px;
  border-radius: 10rem;
  background: #f58320;
  color: #f5f5f5;
  border: none;
  padding: 1.8rem 10rem;
  text-transform: uppercase;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
  cursor: pointer;
  text-align: center; }
  .btn-full:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); }
  .btn-full::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0; }
  .btn-full--animated {
    animation: LoginMove 0.3s ease-out 0.75s;
    animation-fill-mode: backwards; }

.btn-inner {
  font-family: 'Metropolis-Light';
  font-size: 2.5rem;
  color: #999999;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  margin: 2.5rem;
  backface-visibility: hidden;
  transition: all 0.5s; }
  .btn-inner:hover {
    transform: scale(1.04);
    color: #f47521; }
  .btn-inner__line {
    border: 0.3px solid #cccccc;
    width: 80%;
    align-self: center; }
  .btn-inner p {
    font-size: 0.9rem;
    text-transform: uppercase; }
  .btn-inner__table {
    font-size: 1.2rem;
    text-transform: uppercase;
    display: flex; }

.btn-icon-1 {
  font-size: 4rem;
  color: #f47521;
  backface-visibility: hidden;
  overflow: hidden;
  transition: all 0.3s;
  margin-right: 3rem;
  cursor: pointer;
  text-align: right; }
  .btn-icon-1:hover {
    transform: perspective(1px) scale(1.05); }

.btn-icon-2 {
  font-size: 3rem;
  color: #ff817a;
  backface-visibility: hidden;
  overflow: hidden;
  transition: all 0.3s;
  margin-right: 1rem;
  cursor: pointer;
  text-align: right; }
  .btn-icon-2:hover {
    transform: perspective(1px) scale(1.1);
    color: #f6675f; }

.save-icon {
  font-size: 1.4rem;
  display: inline-block;
  margin-top: 0.2rem; }

.btn-img-del {
  position: absolute;
  margin-left: -21rem;
  margin-top: 1rem;
  font-size: 4rem;
  color: #f47521;
  backface-visibility: hidden;
  overflow: hidden;
  transition: all 0.15s;
  cursor: pointer; }
  .btn-img-del:hover {
    transform: perspective(1px) scale(1.1); }

.btn-new {
  color: white;
  border: none;
  outline: none;
  box-shadow: 0.5rem 0.5rem 2rem rgba(0, 0, 0, 0.2);
  border-radius: 6rem;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  align-self: center;
  align-content: center; }
  .btn-new:hover {
    transform: scale(1.01) translateY(-1px);
    box-shadow: 0.5rem 0.5rem 2rem rgba(0, 0, 0, 0.3); }
  .btn-new:active {
    transform: translateY(1px);
    box-shadow: 0.5rem 0.5rem 2rem rgba(0, 0, 0, 0.2); }

.btn-save {
  background-color: #75cc93;
  font-size: 1.5rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  justify-self: center;
  align-self: center;
  align-content: center; }
  .btn-save:hover {
    background-color: #59c880; }

.btn-delete {
  background-color: #f7655e;
  font-size: 1.5rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  justify-self: center;
  align-self: center;
  align-content: center; }
  .btn-delete:hover {
    background-color: #f2554d; }

.button-icon-new {
  font-size: 2rem;
  display: flex;
  justify-content: center;
  justify-self: center;
  align-self: center;
  align-content: center; }

.popup {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(245, 246, 250, 0.8);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column; }
  .popup__content {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-self: center;
    align-self: center;
    width: 85%;
    height: 80%;
    background-color: white;
    box-shadow: 2rem 2rem 4rem rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    overflow: hidden;
    opacity: 0;
    transition: all .1s .1s; }
  .popup:target {
    opacity: 1;
    visibility: visible; }
  .popup:target .popup__content {
    opacity: 1; }
  .popup__close:link, .popup__close:visited {
    color: gray;
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    font-size: 4rem;
    text-decoration: none;
    display: inline-block;
    transition: all .1s;
    line-height: 1; }
  .popup__close:hover {
    color: #f15a24; }

.detail-form {
  position: relative;
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 6rem;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0.5rem 0.5rem 2rem rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden; }
  .detail-form__heading {
    font-size: 2.5rem;
    color: #333333;
    padding-left: 1rem;
    grid-column: 1 / span 2; }
  .detail-form__user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    grid-column: 3 / span 1; }
  .detail-form__button-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    align-items: center; }
  .detail-form__main {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: repeat(3, minmax(15rem, 1fr));
    align-items: center;
    grid-column-gap: 2rem;
    grid-row-gap: 1.5rem;
    grid-column: 2/5; }
  .detail-form__main-new {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-column-gap: 2rem;
    grid-row-gap: 1.5rem;
    grid-column: 2/5; }
  .detail-form__main-story-arc {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    align-items: center;
    grid-column-gap: 2rem;
    grid-row-gap: 1.5rem;
    grid-column: 2/3; }
  .detail-form__main-1 {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: repeat(2, minmax(15rem, 1fr));
    align-items: center;
    grid-column-gap: 2rem;
    grid-row-gap: 1.5rem;
    grid-column: 2/5; }
  .detail-form__main-2 {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: repeat(2, minmax(15rem, 1fr));
    align-items: top;
    grid-column-gap: 2rem;
    grid-row-gap: 1.5rem;
    grid-column: 1/5; }
  .detail-form__author-roles {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: top;
    padding: 2rem;
    grid-column: 1/2; }
  .detail-form__description {
    position: relative;
    height: 35rem;
    grid-column: 1 / 5;
    display: grid; }
    .detail-form__description ::-webkit-scrollbar {
      display: none; }
  .detail-form__description-3 {
    position: relative;
    min-height: 35rem;
    grid-column: 3 / 5;
    grid-row: span 2;
    display: grid; }
    .detail-form__description-3 ::-webkit-scrollbar {
      display: none; }
  .detail-form__description-4 {
    position: relative;
    min-height: 35rem;
    grid-column: span 2;
    display: grid; }
    .detail-form__description-4 ::-webkit-scrollbar {
      display: none; }
  .detail-form__description-1 {
    position: relative;
    height: 35rem;
    grid-column: 2 / 5;
    display: grid; }
    .detail-form__description-1 ::-webkit-scrollbar {
      display: none; }
  .detail-form__description-2 {
    position: relative;
    min-height: 35rem;
    grid-column: 2 / 5;
    display: grid; }
    .detail-form__description-2 ::-webkit-scrollbar {
      display: none; }
  .detail-form__nationality {
    width: 100%;
    height: 35rem;
    grid-column: 1 / 1;
    color: #999999; }
    .detail-form__nationality__searchbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 5rem;
      border-radius: 1.5rem;
      background-color: #f5f5f5;
      margin-bottom: 1rem; }
    .detail-form__nationality__icon {
      font-size: 2rem;
      margin-left: 2rem; }
    .detail-form__nationality__separator {
      font-size: 2rem;
      margin-left: 0.5rem; }
    .detail-form__nationality__input {
      font-family: inherit;
      font-size: 1.4rem;
      background-color: transparent;
      border: none;
      margin-left: 0.5rem;
      width: 100%; }
      .detail-form__nationality__input::placeholder {
        color: #999999; }
      .detail-form__nationality__input:focus {
        outline: none; }
    .detail-form__nationality__country-container {
      height: 80%;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 0.5rem; }
      .detail-form__nationality__country-container::-webkit-scrollbar {
        display: none; }
    .detail-form__nationality__country-row {
      display: flex;
      cursor: pointer;
      align-items: center;
      width: 100%;
      height: 2.5rem;
      padding: 2rem 1rem 2rem 1rem;
      font-family: 'Metropolis-Bold', sans-serif;
      font-size: 1.4rem;
      color: #333333;
      border-radius: 1.5rem;
      transition: all 0.4s; }
      .detail-form__nationality__country-row.checked {
        /* Add styles for checked state */
        background-color: #ebecee; }
      .detail-form__nationality__country-row__item {
        width: 100%;
        margin-left: 1rem;
        position: relative; }
        .detail-form__nationality__country-row__item__check {
          cursor: pointer;
          position: absolute;
          top: 0;
          right: -1.5rem;
          opacity: 0;
          width: 1.6rem;
          height: 1.6rem; }
          .detail-form__nationality__country-row__item__check:checked {
            opacity: 1; }
        .detail-form__nationality__country-row__item:hover .detail-form__nationality__country-row__item__check {
          opacity: 1; }
      .detail-form__nationality__country-row:hover {
        border-radius: 1.5rem;
        background-color: #ebecee; }
  .detail-form__icon {
    margin-bottom: -0.3rem; }
  .detail-form__aliases {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    grid-column: span 2;
    margin-bottom: 2rem; }
    @media (min-width: 1600px) {
      .detail-form__aliases {
        grid-template-columns: 1fr 1fr 1fr; } }
  .detail-form__connect-box {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    grid-column: span 2;
    margin-bottom: 2rem; }
  .detail-form__rel-char {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    grid-column: span 2; }

.entity-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 1.2rem; }
  .entity-info__id {
    background-image: linear-gradient(to right, #f47521, #f58320);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
    border-radius: 1rem;
    color: white;
    padding: 0.3rem; }
  .entity-info__slug {
    color: #777777;
    background-image: linear-gradient(to right, #999999, #999999);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    color: white;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem; }

.grid-1 {
  grid-template-rows: min-content 26rem;
  grid-template-columns: 26rem 1fr 1fr 1fr; }

.grid-2 {
  grid-template-rows: min-content 25rem 1fr;
  grid-template-columns: 26rem 1fr 1fr 1fr; }

.grid-3 {
  grid-template-rows: min-content min-content 1fr;
  grid-template-columns: 26rem 1fr 1fr 1fr; }

.grid-4 {
  grid-template-columns: 26rem 1fr 1fr 1fr; }

.input-box {
  display: flex;
  position: relative;
  align-items: center;
  background-color: #f5f5f5;
  border: none;
  border-radius: 1.7rem;
  height: 6rem;
  width: 100%;
  min-width: 10rem; }

.input-box-alias {
  display: flex;
  position: relative;
  align-items: center;
  background-color: #f5f5f5;
  border: none;
  border-radius: 1.7rem;
  height: 6rem;
  width: 100%;
  min-width: 10rem; }

.input-box-unchecked {
  display: flex;
  position: relative;
  align-items: center;
  background-color: #fafafa;
  border: none;
  border-radius: 1.7rem;
  height: 6rem;
  width: 100%; }

.input-box-1 {
  display: flex;
  position: relative;
  align-items: center;
  background-color: #f5f5f5;
  border: none;
  border-radius: 1.7rem;
  height: 100%;
  grid-row: span 2;
  width: 100%;
  max-height: 14rem; }

.input-box-2 {
  display: flex;
  position: relative;
  align-items: center;
  background-color: #f5f5f5;
  border: none;
  border-radius: 1.7rem;
  height: 100%;
  grid-row: span 3;
  width: 100%; }

.input-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 4.2rem;
  height: 100%;
  width: 100%; }

.input-area-alias {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%; }

.input-area-checkbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.3rem;
  height: 100%;
  width: 100%; }

.input-area-multi {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 4rem;
  margin-top: 2.5rem;
  height: 100%;
  width: 100%;
  font-size: 1.2rem; }

.input-alias {
  font-family: 'Metropolis-Medium', sans-serif;
  font-size: 1.4rem;
  color: #333333;
  background-color: transparent;
  border: none;
  text-align: center;
  height: auto;
  outline: none;
  overflow: hidden; }

.input {
  font-family: 'Metropolis-Medium', sans-serif;
  font-size: 1.4rem;
  color: #333333;
  background-color: transparent;
  border: none;
  width: auto;
  height: auto;
  outline: none;
  overflow: hidden;
  padding-top: 0.5rem;
  margin-right: 2rem; }
  .input--disabled {
    color: #83888f;
    border: none;
    font-size: 1.2rem; }
  .input::-webkit-calendar-picker-indicator {
    display: none; }
  .input::placeholder {
    font-family: 'Metropolis-Light', sans-serif;
    color: #777777; }
  .input__icon {
    position: absolute;
    margin-left: 1.75rem;
    font-size: 1.5rem;
    color: #999999; }
  .input__icon-1 {
    margin-left: -2rem;
    font-size: 1.5rem;
    color: #999999; }
  .input__select {
    border: none;
    background-color: transparent;
    width: 90%;
    outline: none; }
  .input__textarea {
    min-height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    font-family: 'Metropolis-Medium', sans-serif;
    font-size: 1.4rem;
    color: black;
    background-color: #f5f5f5;
    border: none;
    border-radius: 1.7rem;
    outline: none;
    padding: 1.5rem;
    width: 100%;
    grid-column: 1/4;
    resize: none;
    align-self: stretch; }
    .input__textarea::placeholder {
      font-family: 'Metropolis-Light', sans-serif;
      color: #cccccc; }
  .input__label {
    font-size: 1.2rem;
    color: #999999;
    padding-bottom: 0.5rem;
    font-family: 'Metropolis-Light', sans-serif; }

.input__label-disabled {
  font-size: 1.2rem;
  color: #c1c1c1;
  padding-bottom: 0.5rem;
  font-family: 'Metropolis-Light', sans-serif; }

.required-mark-box {
  position: absolute;
  top: 2.15rem;
  right: 0rem; }

span.required-mark {
  margin: 1rem;
  font-size: 1.8rem;
  color: #f7931e; }

.img-entity {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 10%; }

.img-upload__box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 2rem;
  z-index: 1;
  align-items: center;
  justify-content: center;
  padding: 2rem 2rem 4rem 2rem;
  text-align: center; }

.img-upload__text {
  padding: 1rem;
  font-size: 1.2rem;
  color: black;
  font-family: 'Metropolis-Medium', sans-serif; }

.img-upload__btn-box {
  margin-top: -2rem;
  z-index: 2; }

.img-upload__icon {
  font-size: 5rem; }

.cover-upload__box {
  display: flex;
  flex-direction: column;
  width: 5rem;
  height: 6rem;
  background-color: #f5f5f5;
  border-radius: 2rem;
  z-index: 1;
  align-items: center;
  justify-content: center;
  text-align: center; }

.cover-upload__icon {
  font-size: 2.5rem; }

.input-upload {
  display: none; }

.img-uploaded {
  display: none; }

.upload-label {
  cursor: pointer; }
  .upload-label__hiden {
    display: none; }

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>'); }

.container-heading {
  display: flex;
  height: 1.5rem;
  grid-column: span 3;
  font-size: 1.2rem;
  font-family: 'Metropolis-Medium', sans-serif;
  padding-left: 1rem; }

.button-container {
  display: flex;
  align-items: center; }

.container-heading-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem; }

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 0 !important; }

.alias-ticket {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0 !important;
  max-height: 20rem; }

.flex-row-box {
  width: 100%;
  padding-bottom: 1rem; }

.book-format-box {
  position: relative;
  width: 100%;
  grid-column: 1 / 5;
  display: flex;
  flex-direction: column; }

.book-format {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 5rem repeat(4, 1fr) 7rem 12rem 8rem 4rem;
  width: 100%;
  grid-gap: 1rem;
  grid-auto-flow: row;
  justify-items: center;
  align-items: center;
  margin-bottom: 1rem; }

.input-format {
  width: 100%;
  height: 4rem;
  background-color: #f5f5f5;
  border-radius: 1rem;
  padding-left: 1rem;
  border: none;
  color: #333333; }
  .input-format:focus {
    outline: none; }

.single-select {
  width: 100%;
  height: 3.8rem;
  background-color: #f5f5f5;
  border-radius: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: none;
  color: #333333; }
  .single-select:focus {
    outline: none; }

.entity-table {
  font-family: 'Metropolis-Regular', sans-serif;
  font-size: 1.2rem;
  border-radius: 10px; }
  .entity-table__header, .entity-table__footer, .entity-table__row {
    font-family: inherit;
    border-radius: 10px;
    display: grid;
    align-items: center;
    padding: 0 2.5rem; }
  .entity-table__header {
    color: #83888f;
    background-color: #f4f4fb;
    height: 4rem; }
  .entity-table__footer {
    color: #83888f;
    background-color: white;
    height: 5rem;
    padding-right: 5rem; }
  .entity-table__settings-icon {
    font-size: 1.6rem;
    color: #777777; }
  .entity-table__row {
    color: #333333;
    height: 6rem;
    font-size: 1.2rem; }
    .entity-table__row:nth-child(odd) {
      background-color: #fafafc; }
    .entity-table__row__link {
      color: #333333;
      transition: all .2s; }
    .entity-table__row__link:hover {
      color: #f7931e; }

.table-bookmark {
  height: 5rem;
  border-radius: 10px 10px 0 0;
  display: flex;
  position: relative;
  z-index: 1; }
  .table-bookmark__icon {
    height: 2rem;
    width: 2rem;
    fill: #bab8ce;
    margin-right: 1rem;
    margin-left: 3rem; }
    .table-bookmark__icon--active {
      fill: #f15a24; }
  .table-bookmark__button {
    background-color: #f8f8fd;
    border: 1px solid white;
    color: #bab8ce;
    font-family: 'Metropolis-Regular', sans-serif;
    font-size: 1.4rem;
    margin-right: 1rem;
    height: 5rem;
    width: 20rem;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    transition: all .2s;
    backface-visibility: hidden; }
    .table-bookmark__button:hover {
      cursor: pointer;
      background-color: white;
      color: #f15a24; }
    .table-bookmark__button--active {
      background-color: white;
      color: #f15a24; }

.dropdown {
  display: inline-block;
  position: relative;
  margin-right: 1.3rem; }
  .dropdown__button {
    display: inline-block;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 10px 30px 10px 20px;
    background-color: white;
    cursor: pointer;
    white-space: nowrap;
    transition: all .3s;
    max-width: fit-content; }
    .dropdown__button::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #777777; }
    .dropdown__button:hover {
      background-color: #eeeeee; }
  .dropdown__input {
    display: none; }
  .dropdown__menu {
    position: absolute;
    top: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    list-style-type: none; }
    .dropdown__menu li {
      padding: .7rem 2.5rem;
      cursor: pointer;
      white-space: nowrap; }
    .dropdown__menu li:hover {
      background-color: #f6f6f6; }
    .dropdown__menu li a {
      display: block;
      margin: -1rem -2rem;
      padding: 1rem 2rem; }
    .dropdown__menu li.divider {
      padding: 0;
      border-bottom: 1px solid #cccccc; }
  .dropdown__input + .dropdown__menu {
    display: none; }
  .dropdown__input:checked + .dropdown__menu {
    display: block; }

.entity-check {
  width: 2rem;
  height: 2rem;
  position: absolute;
  opacity: 0; }
  .entity-check + label {
    position: relative;
    cursor: pointer;
    padding: 0; }
  .entity-check + label:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    border: 1px solid #cccccc;
    margin-left: 1rem;
    transition: all 0.4s; }
  .entity-check:hover + label:before {
    background-color: #f7931e;
    border: 1px solid #f7931e; }
  .entity-check:checked + label:before {
    background-color: #f7931e;
    border: 1px solid #f7931e; }
  .entity-check:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background-color: white;
    margin-left: 1rem;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg); }

.entity-check-1 {
  width: 4rem;
  height: 4rem;
  opacity: 1; }
  .entity-check-1 + label {
    cursor: pointer;
    padding: 0;
    opacity: 1; }
  .entity-check-1 + label:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    border: 1px solid #cccccc;
    margin-left: 1rem;
    transition: all 0.4s;
    opacity: 0; }
  .entity-check-1:hover + label:before {
    border: 2px solid #f7931e;
    opacity: 1; }
  .entity-check-1:checked + label:before {
    background-color: #f7931e;
    border: 1px solid #f7931e;
    opacity: 1; }
  .entity-check-1:checked + label:after {
    content: '';
    position: absolute;
    opacity: 1;
    left: 14.1rem;
    top: 9px;
    background-color: white;
    margin-left: 1rem;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg); }

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center; }

.loader,
.loader:before,
.loader:after {
  background: #f47521;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
  transition: all .3s; }

.loader {
  color: #f47521;
  text-indent: -9999em;
  margin: 8.8rem auto;
  position: relative;
  font-size: 1.8rem;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  transition: all .3s; }

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: ''; }

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.loader:after {
  left: 1.5em; }

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

.react-select-container {
  width: 100% !important;
  margin-left: -1rem !important; }

.react-select__control {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  max-height: 10rem !important;
  max-width: 98% !important;
  overflow-y: auto !important;
  font-family: 'Metropolis-Medium', sans-serif;
  margin-top: -0.5rem; }
  .react-select__control:focus, .react-select__control:focus-visible {
    outline: none !important; }
  .react-select__control::-webkit-scrollbar {
    width: 0;
    /* Skryjeme scrollbar v prohlížečích, které podporují WebKit (Safari, Chrome) */ }

.react-select__multi-value__label {
  font-size: 1.3rem !important; }

.react-select__menu {
  max-width: 90% !important; }

.react-select__dropdown-indicator {
  display: none !important; }

.react-select__indicator-separator {
  display: none !important; }

.react-select__multi-value__remove {
  color: #333333 !important;
  transition: all 0.3s !important; }
  .react-select__multi-value__remove:hover {
    background-color: #f47521 !important;
    color: white !important; }

.react-select-disabled-container {
  width: 100% !important;
  margin-left: -1rem !important; }

.react-select-disabled__control {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  max-height: 10rem !important;
  max-width: 98% !important;
  overflow-y: auto !important;
  font-family: 'Metropolis-Medium', sans-serif;
  margin-left: -1rem !important; }
  .react-select-disabled__control:focus, .react-select-disabled__control:focus-visible {
    outline: none !important; }
  .react-select-disabled__control::-webkit-scrollbar {
    width: 0;
    /* Skryjeme scrollbar v prohlížečích, které podporují WebKit (Safari, Chrome) */ }

.react-select-disabled__multi-value__label {
  font-size: 1.3rem !important;
  color: #777777 !important; }

.react-select-disabled__menu {
  max-width: 90% !important; }

.react-select-disabled__dropdown-indicator {
  display: none !important; }

.react-select-disabled__indicator-separator {
  display: none !important; }

.react-select-disabled__multi-value__remove {
  display: none;
  opacity: 0; }

.react-select-xl-container {
  width: 100% !important;
  margin-left: -1rem !important; }

.react-select-xl__control {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  max-height: 17rem !important;
  max-width: 98% !important;
  overflow-y: auto !important;
  font-family: 'Metropolis-Medium', sans-serif; }
  .react-select-xl__control:focus, .react-select-xl__control:focus-visible {
    outline: none !important; }
  .react-select-xl__control::-webkit-scrollbar {
    width: 0;
    /* Skryjeme scrollbar v prohlížečích, které podporují WebKit (Safari, Chrome) */ }

.react-select-xl__multi-value__label {
  font-size: 1.3rem !important; }

.react-select-xl__menu {
  max-width: 90% !important; }

.react-select-xl__dropdown-indicator {
  display: none !important; }

.react-select-xl__indicator-separator {
  display: none !important; }

.react-select-xl__multi-value__remove {
  color: #333333 !important;
  transition: all 0.3s !important; }
  .react-select-xl__multi-value__remove:hover {
    background-color: #f47521 !important;
    color: white !important; }

.react-single-select {
  max-height: 2rem !important; }
  .react-single-select__control {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    height: 5rem !important;
    margin-left: -0.9rem;
    margin-top: -2.3rem;
    font-size: 1.4rem !important;
    font-family: 'Metropolis-Medium', sans-serif; }
    .react-single-select__control:focus, .react-single-select__control:focus-visible {
      outline: none !important; }
  .react-single-select__menu {
    margin-left: -0.9rem; }
  .react-single-select__menu-list {
    font-size: 1.4rem !important; }
  .react-single-select__value-container {
    margin-top: 2.3rem; }

.single-select-disabled__control {
  background-color: #f5f5f5 !important;
  border: none !important;
  border-radius: 0.7rem !important;
  box-shadow: none !important;
  outline: none !important;
  font-size: 1.2rem !important;
  font-family: 'Metropolis-Medium', sans-serif; }

.single-select-number-disabled__control {
  background-color: #f5f5f5 !important;
  border: none !important;
  border-radius: 0.7rem !important;
  box-shadow: none !important;
  outline: none !important;
  font-size: 1.2rem !important;
  font-family: 'Metropolis-Medium', sans-serif;
  padding-left: 3.5rem; }

.input-label {
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-family: 'Metropolis-Light', sans-serif;
  font-size: 1.2rem;
  color: #999999; }

.input-label-info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: #999999;
  padding: 1rem;
  align-items: center;
  border-radius: 1rem;
  font-family: 'Metropolis-Light', sans-serif;
  font-size: 1.2rem;
  background-color: #fafafa;
  margin-bottom: 1rem; }

.createable-select-s-container {
  width: 100% !important;
  padding: 1rem !important; }

.createable-select-s__control {
  background-color: #f5f5f5 !important;
  border: none !important;
  border-radius: 0.7rem !important;
  box-shadow: none !important;
  outline: none !important;
  font-size: 1.2rem !important;
  font-family: 'Metropolis-Medium', sans-serif;
  width: 100%;
  height: 2rem;
  padding: 1rem; }

.createable-select-s__menu-list {
  font-size: 1.2rem !important; }

.popup-content {
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  height: auto;
  transition: all 0.3s;
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.4);
  border: 1px solid #f5f5f5;
  backdrop-filter: blur(2px); }

.popup-arrow {
  color: #cccccc; }

[role='tooltip'].popup-content {
  width: auto;
  margin: auto;
  border-radius: 5px;
  transition: all 0.3s; }

.popup-overlay {
  background: rgba(0, 0, 0, 0.5); }

[data-popup='tooltip'].popup-overlay {
  background: transparent; }

.topbar-box {
  background-color: rgba(245, 246, 250, 0.8);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  position: fixed;
  z-index: 100;
  padding-left: 8.5rem;
  width: 100%;
  padding-bottom: 20px; }

.top-bar {
  display: flex;
  position: relative;
  height: 6rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
  margin: 2.5rem 2.5rem 0; }

.search {
  flex: 0 0 40%;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-right: auto; }
  .search__input {
    font-family: inherit;
    font-size: 1.4rem;
    background-color: transparent;
    width: 100%;
    border: none;
    padding: 0.7rem 1rem;
    margin-left: 5.5rem; }
    .search__input:focus {
      outline: none; }
    .search__input::placeholder {
      color: #999999; }
  .search__icon {
    font-size: 2.5rem;
    position: absolute;
    height: 2rem;
    width: 3rem;
    color: #83888f;
    margin-left: 2rem;
    padding-right: 1rem;
    border-right: 1.5px solid #cccccc; }

.user-nav {
  display: flex;
  align-items: center;
  align-self: stretch; }
  .user-nav > * {
    padding: 0 2rem;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center; }
  .user-nav > *:not(:last-child) {
    border-right: 3px solid #f5f5f5; }
  .user-nav__icon-box {
    position: relative;
    background-color: transparent;
    transition: all 0.3s; }
    .user-nav__icon-box--bg:hover {
      background-color: #f5f5f5; }
  .user-nav__icon {
    font-size: 3rem;
    color: #777777; }
    .user-nav__icon--add {
      font-size: 3.5rem;
      color: #f15a24;
      backface-visibility: hidden;
      overflow: hidden;
      transition: all 0.2s; }
      .user-nav__icon--add:hover {
        transform: perspective(1px) scale(1.1); }
  .user-nav__date {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 1.5rem; }
  .user-nav__notification {
    font-size: 0.8rem;
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 50%;
    background-color: #f15a24;
    color: white;
    position: absolute;
    top: 1.5rem;
    right: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center; }
  .user-nav__user-photo {
    height: 3.75rem;
    width: 3.75rem;
    border-radius: 50%;
    border: none;
    position: relative;
    background-color: transparent;
    margin-right: auto;
    backface-visibility: hidden;
    overflow: hidden;
    transition: all 0.3s;
    cursor: pointer; }
    .user-nav__user-photo:hover {
      transform: scale(1.05); }

.side-bar {
  position: fixed;
  display: flex;
  min-width: 8.5rem;
  flex-direction: column;
  background-color: white;
  align-items: center;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  height: 110vh;
  z-index: 101; }

.logo-box {
  width: inherit;
  align-items: center;
  display: flex;
  flex-direction: column; }
  .logo-box__logo {
    margin-top: 2.5rem;
    margin-bottom: .8rem;
    width: 3.7em; }
  .logo-box__text {
    font-size: 1.4rem; }

.side-nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-top: 2rem; }
  .side-nav__item {
    padding: 2rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: all .2s;
    font-size: 2.5rem;
    color: #83888f; }
    .side-nav__item:hover {
      box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2); }
  .side-nav__item:hover .side-nav__icon {
    color: #f15a24; }
  .side-nav__icon {
    font-size: 2.5rem;
    color: #83888f; }
    .side-nav__icon--active {
      color: #f15a24; }
  .side-nav__item--active {
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2); }

.side-nav__btn {
  color: #83888f;
  background-color: pink;
  margin-bottom: 1rem;
  padding: 1.5rem;
  font-size: 2.5rem;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  transition: all .2s;
  background-color: white; }
  .side-nav__btn:hover {
    color: #f15a24;
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2); }
  .side-nav__btn--active {
    color: blue;
    background-color: pink; }

.side-nav__btn--active {
  color: blue;
  background-color: pink; }

.menu-items a {
  display: block;
  position: relative;
  font-size: 2rem;
  color: #83888f;
  text-decoration: none;
  text-align: center;
  padding: 1.5rem;
  border-radius: 5px;
  transition: all .2s; }

.menu-items a:hover {
  background-color: #f2f2f2;
  color: #f15a24; }

.dropdown {
  position: absolute;
  left: 7.05rem;
  top: 17.5rem;
  display: flex;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
  z-index: 9999;
  padding: 0.4rem;
  border-radius: 8px;
  list-style: none;
  background-color: white;
  display: none;
  transition: all .2s; }

.dropdown.show {
  display: block; }

.adding-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(204, 204, 204, 0.7);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  height: 100vh;
  width: 100vw;
  z-index: 1000; }

.adding-modal {
  position: absolute;
  top: 4%;
  left: 2.5%;
  right: 2.5%;
  bottom: 4%;
  background-color: white;
  border-radius: 5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  outline: none;
  border: hidden;
  width: 95%;
  height: 92%;
  overflow: hidden;
  overflow-y: scroll; }

.adding-tabs {
  display: flex;
  flex-direction: column; }
  .adding-tabs__nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    color: #83888f;
    border-bottom: 2px solid #f5f5f5;
    -webkit-tap-highlight-color: transparent; }
  .adding-tabs__box {
    display: flex;
    flex-direction: row;
    text-align: center;
    padding-left: 4rem; }
  .adding-tabs__tab {
    list-style: none;
    text-decoration: none;
    background-color: none;
    outline: none; }
  .adding-tabs__nav-item {
    justify-content: center;
    border: none;
    border-bottom: 2px solid #f5f5f5;
    color: #999999;
    margin-bottom: -.2rem;
    margin-right: 1rem;
    position: relative;
    list-style: none;
    outline: none;
    padding: 2.5rem 1rem 2rem 1rem;
    cursor: pointer;
    transition: all .2s;
    font-family: 'Metropolis-ExtraLight', sans-serif;
    font-size: 1.4rem;
    align-self: center;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent; }
    .adding-tabs__nav-item:hover {
      color: #f47521;
      border-bottom: 2px solid #f47521; }
    .adding-tabs__nav-item--selected {
      color: #f47521;
      border-bottom: 2px solid #f47521; }

.create-publishing {
  padding: 3rem; }

.section-login {
  height: 100vh;
  background-image: linear-gradient(rgba(245, 246, 250, 0.9) 0%, rgba(245, 246, 250, 0.9) 100%), url(/src/utils/img/bg-login.jpeg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center; }

.login-box {
  width: 112rem;
  height: 62rem;
  display: flex;
  position: relative;
  border-radius: 50px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
  overflow: hidden; }
  .login-box__info {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: linear-gradient(to right bottom, #f15a24, #f7931e);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    text-align: center;
    align-items: center; }
    .login-box__info__logo {
      height: 11rem;
      margin-bottom: 5rem; }
    .login-box__info__text {
      text-align: left;
      width: 60%;
      margin-bottom: 4rem; }
    .login-box__info__heading-1 {
      margin-bottom: 1rem; }
      .login-box__info__heading-1:after {
        content: "";
        /* This is necessary for the pseudo element to work. */
        display: block;
        /* This will put the pseudo element on its own line. */
        padding-top: -4rem;
        width: 7.5rem;
        /* Change this to whatever width you want. */
        border-bottom: 5px solid #f5f5f5;
        /* This creates the border. Replace black with whatever color you want. */
        margin-top: 1.5rem;
        margin-bottom: 3.5rem; }
    .login-box__info__btn {
      margin-top: 5.5rem; }
  .login-box__form {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.7);
    text-align: center;
    justify-content: center;
    align-items: center; }
    .login-box__form__container {
      width: 50%; }
    .login-box__form__heading-2 {
      margin-bottom: 7rem;
      margin-top: 3rem; }
      .login-box__form__heading-2:after {
        content: "";
        /* This is necessary for the pseudo element to work. */
        display: block;
        /* This will put the pseudo element on its own line. */
        margin: 0 auto;
        /* This will center the border. */
        width: 5rem;
        /* Change this to whatever width you want. */
        padding-top: 1.5rem;
        /* This creates some space between the element and the border. */
        border-bottom: 5px solid #f47521;
        /* This creates the border. Replace black with whatever color you want. */ }
    .login-box__form__group {
      width: 100%; }
    .login-box__form__group:not(:last-child) {
      margin-bottom: 3rem; }
    .login-box__form__input {
      font-family: 'Metropolis-Light', sans-serif;
      font-size: 1.6rem;
      color: #777777;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #cccccc;
      padding-bottom: 1rem;
      display: block;
      transition: all .3s;
      width: 100%; }
      .login-box__form__input:focus {
        outline: none;
        border-bottom: 1.2px solid #f58320; }
      .login-box__form__input::-webkit-input-placeholder {
        color: #cccccc; }
    .login-box__form__label {
      font-family: 'Metropolis-Medium', sans-serif;
      font-size: 1.2rem;
      color: #cccccc;
      text-align: left;
      margin-top: .7rem;
      display: block;
      transition: all .3s; }
    .login-box__form__input:placeholder-shown + .login-box__form__label {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-4rem); }
    .login-box__form__btn {
      margin-top: 7.7rem; }

.login {
  background-color: hotpink; }
  .login__circle-1 {
    border: 34px solid white;
    background-color: transparent;
    width: 17rem;
    height: 17rem;
    position: absolute;
    border-radius: 50%;
    left: 50%;
    top: 40rem;
    transform: translateX(-8.5rem);
    z-index: 5;
    box-shadow: 0 0 4rem rgba(0, 0, 0, 0.2); }
  .login__circle-2 {
    border: 42px solid #ebecee;
    background-color: transparent;
    width: 21rem;
    height: 21rem;
    position: absolute;
    border-radius: 35%;
    left: 102rem;
    top: 9rem;
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(45deg); }

.dashboard {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr) 1.2fr 1fr;
  grid-gap: 2.5rem; }

.item {
  background-color: white;
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2rem;
  transition: transform 0.5s; }
  .item:hover {
    transform: perspective(1px) scale(1.009); }

.item-nonscale {
  background-color: white;
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2rem;
  transition: transform 0.5s; }

.last-added {
  grid-column: 1 / 1;
  background-image: url(/src/utils/img/default-cover.jpg);
  background-size: cover;
  background-position: top; }

.overview {
  grid-column: 2 / 6; }
  .overview__heading {
    display: flex;
    margin-bottom: 2rem; }
  .overview__filter {
    font-family: inherit;
    font-size: 1.2rem;
    color: #777777;
    margin-left: auto; }
  .overview__statistics {
    width: 100%;
    height: 90%;
    display: flex; }
  .overview__numbers {
    flex-basis: 18%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
  .overview__chart {
    flex-basis: 68%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: inherit;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    color: #777777; }
  .overview__legend {
    flex-basis: 15%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    .overview__legend__item {
      font-family: inherit;
      font-size: 1.5rem;
      color: #777777;
      text-align: right;
      margin-top: 1rem;
      display: flex; }
      .overview__legend__item--1::before {
        content: "";
        height: 1.2rem;
        width: 5rem;
        background-color: #f47521;
        margin-right: 2rem; }
      .overview__legend__item--2::before {
        content: "";
        height: 1.2rem;
        width: 5rem;
        background-color: #f6f6f8;
        margin-right: 2rem; }
    .overview__legend__item:not(:first-child) {
      margin-bottom: 3rem; }

.dashchart-item {
  font-family: inherit;
  font-size: 1.5rem;
  color: #777777;
  margin-bottom: 1rem; }
  .dashchart-item__value {
    font-family: "Metropolis-Medium", sans-serif;
    font-size: 3rem;
    display: block;
    margin-top: 2rem; }

.dashchart-col-container {
  display: flex;
  width: 80%;
  justify-content: space-around;
  align-items: flex-end;
  border-bottom: 1px solid #f6f6f8;
  border-top: 1px solid #f6f6f8; }

.dashchart-col {
  width: 1.2rem; }
  .dashchart-col-1 {
    height: 3rem;
    background-color: #f6f6f8; }
  .dashchart-col-2 {
    height: 8.5rem;
    background-color: #f6f6f8; }
  .dashchart-col-3 {
    height: 6.6rem;
    background-color: #f6f6f8; }
  .dashchart-col-4 {
    height: 11.5rem;
    background-color: #f58320; }
  .dashchart-col-5 {
    height: 9rem;
    background-color: #f6f6f8; }
  .dashchart-col-6 {
    height: 8.2rem;
    background-color: #f6f6f8; }
  .dashchart-col-7 {
    height: 11rem;
    background-color: #f58320; }
  .dashchart-col-8 {
    height: 11.7rem;
    background-color: #f58320; }
  .dashchart-col-9 {
    height: 17rem;
    background-color: #f58320; }
  .dashchart-col-10 {
    height: 13.5rem;
    background-color: #f58320; }
  .dashchart-col-11 {
    height: 16rem;
    background-color: #f58320; }
  .dashchart-col-12 {
    height: 19rem;
    background-color: #f58320; }
  .dashchart-col-13 {
    display: flex;
    height: 100%;
    flex-direction: column-reverse;
    justify-content: space-between; }

.dashchart-title-container {
  display: flex;
  width: 80%;
  justify-content: space-around;
  align-items: flex-end;
  text-align: center;
  margin-top: 1rem; }

.hubmates {
  grid-column: 6 / 6; }
  .hubmates__container {
    padding: 8px;
    overflow: auto;
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-height: 22rem;
    flex-wrap: wrap; }
  .hubmates__status {
    height: 5.5rem;
    width: 5.5rem;
    background-color: #558b2f;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem; }
    .hubmates__status--inactive {
      height: 5.5rem;
      width: 5.5rem;
      background-color: #777777;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem; }
  .hubmates__photo {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    cursor: pointer; }

.last-activity {
  grid-column: 1 / 4;
  grid-row: 2 / 4;
  position: relative; }
  .last-activity__heading {
    display: flex; }
  .last-activity__filter-box {
    display: flex;
    margin-left: auto;
    height: 1.5rem; }
  .last-activity__filter {
    font-size: 1.2rem;
    margin-left: 2rem;
    flex-wrap: nowrap;
    cursor: pointer;
    color: #777777; }
    .last-activity__filter-active {
      font-family: "Metropolis-Medium", sans-serif;
      color: #f7931e; }
  .last-activity__container {
    height: 100%;
    padding-top: 2.5rem; }
  .last-activity__log {
    display: flex;
    align-items: center; }
    .last-activity__log:not(:last-child) {
      margin-bottom: 5rem; }
  .last-activity__photo {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    margin-right: 1.5rem;
    cursor: pointer;
    align-self: flex-start; }
  .last-activity__message {
    display: flex;
    flex-direction: column; }
  .last-activity__name {
    font-family: "Metropolis-Medium", sans-serif;
    font-size: 1.5rem;
    color: #777777;
    cursor: pointer; }
  .last-activity__logtext {
    font-family: inherit;
    font-size: 1.5rem;
    color: #83888f;
    margin-top: 0.3rem; }
  .last-activity__link {
    color: #f7931e;
    cursor: pointer; }
  .last-activity__gallery {
    display: flex; }
    .last-activity__gallery-item {
      width: 4rem;
      margin: 0.5rem; }

.month-results {
  grid-column: 4 / 4; }
  .month-results__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center; }
  .month-results__loader {
    background-color: transparent;
    border-right: 0.5rem solid #f15a24;
    border-left: 0.5rem solid #f5f7f9;
    border-radius: 50%;
    border-top: 0.5rem solid #f15a24;
    border-bottom: 0.5rem solid #f15a24;
    width: 16rem;
    height: 16rem;
    transform: rotate(45deg);
    display: flex;
    justify-content: center;
    align-items: center; }
  .month-results__value {
    font-family: "Metropolis-Medium", sans-serif;
    font-size: 4.5rem;
    color: #777777;
    transform: rotate(-45deg); }
  .month-results__info {
    font-family: inherit;
    font-size: 1.2rem;
    color: #777777;
    text-align: center; }
    .month-results__info--text {
      display: block;
      margin-top: 0.5rem; }

.team-events {
  grid-column: 5 / 5; }
  .team-events__container {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly; }
  .team-events__icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    color: #937ebb; }
  .team-events__item {
    display: flex;
    font-family: inherit;
    align-items: center;
    font-size: 1.2rem;
    color: #777777; }
    .team-events__item:not(:last-child) {
      border-bottom: 2px solid #f5f7f9;
      padding-bottom: 2.5rem; }
    .team-events__item__date {
      margin-left: auto;
      font-size: 1rem; }

.rank {
  grid-column: 6 / 6; }
  .rank__heading {
    display: flex;
    margin-bottom: 1.4rem;
    margin-left: auto;
    width: 100%; }
  .rank__container {
    display: flex;
    flex-direction: column;
    height: 90%;
    justify-content: space-around; }
  .rank__item {
    display: flex;
    justify-content: space-between;
    font-family: inherit;
    font-size: 1.2rem;
    color: #777777; }

.nothing-so-far {
  grid-column: 4 / 7; }

.authors {
  max-width: 100%;
  padding: 5px 5px;
  background-color: white;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; }

.authors-bulk {
  display: flex;
  background-color: white;
  height: 8.5rem;
  border-radius: 0 10px 0 0;
  position: relative;
  z-index: 6;
  align-items: center;
  justify-content: space-between;
  padding: 3rem; }

.entity-search {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f5f5f5;
  border-radius: 100px; }
  .entity-search__input {
    font-family: inherit;
    font-size: 1.4rem;
    background-color: transparent;
    width: 100%;
    border: none;
    width: 32rem;
    height: 4.8rem;
    margin-left: 2rem; }
    .entity-search__input::placeholder {
      color: #999999; }
    .entity-search__input:focus {
      outline: none; }
  .entity-search__icon {
    height: 2rem;
    width: 2rem;
    fill: #83888f;
    margin-left: 2rem; }
  .entity-search__filter-box {
    height: 3.6rem;
    width: 5rem;
    border-radius: 100px;
    margin-right: .5rem;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    .entity-search__filter-box__icon {
      height: 2rem;
      width: 2rem;
      fill: #83888f;
      transition: all .2s; }
    .entity-search__filter-box:hover .entity-search__filter-box__icon {
      fill: #f15a24; }

.users {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2.5rem; }

.user__cart {
  background-color: white;
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2rem;
  height: 30rem;
  transition: all .5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%; }
  .user__cart:hover {
    box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.1); }
  .user__cart__photo {
    height: 8rem;
    width: 8rem;
    margin-top: 1.25rem;
    border-radius: 50%;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.2); }
  .user__cart__name {
    font-size: 1.8rem;
    font-family: 'Metropolis-Medium', sans-serif;
    color: #333333; }
  .user__cart__email {
    font-size: 1.2rem;
    font-family: 'Metropolis-Medium', sans-serif;
    color: #777777; }
  .user__cart__function {
    font-size: 1.2rem;
    font-family: 'Metropolis-Medium', sans-serif;
    color: #777777;
    display: block;
    padding-top: .7rem; }
  .user__cart__button {
    font-size: 1.1rem;
    font-family: 'Metropolis-Bold';
    padding: 2rem 4rem;
    background-color: transparent;
    text-transform: uppercase;
    border: 1px solid #f15a24;
    margin-bottom: 1.25rem;
    border-radius: 8px;
    box-shadow: 0.1rem 0.1rem 0.8rem rgba(0, 0, 0, 0.1);
    transition: all .3s;
    color: #f15a24;
    cursor: pointer; }
    .user__cart__button:hover {
      transform: scale(1.03);
      color: #f5f5f5;
      background-color: #f15a24; }

.user-profile__bio {
  display: flex;
  width: 100%;
  height: 40rem;
  background-color: white;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-radius: 1rem; }

.user-profile__initials {
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  border-right: 2px solid #ebecee;
  padding: 2rem; }

.user-profile__img {
  width: 16rem;
  height: 16rem;
  border-radius: 8rem;
  overflow: hidden;
  margin-top: 2rem; }

.user-profile__name {
  font-family: 'Metropolis-Light', sans-serif;
  font-size: 2.6rem;
  color: #333333;
  margin-top: 2rem; }

.user-profile__info {
  font-family: 'Metropolis-Light', sans-serif;
  font-size: 1.4rem;
  color: #333333;
  margin-top: 1.2rem; }

.user-profile__body {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem; }

.user-profile__bigtext {
  font-family: 'Metropolis-Bold', sans-serif;
  font-size: 8rem;
  color: #ebecee;
  text-transform: uppercase; }

.my-profile__heading {
  font-family: inherit;
  font-size: 2.5rem;
  text-transform: uppercase;
  margin-bottom: 2rem; }
  .my-profile__heading:not(:first-child) {
    margin-top: 5rem; }

.my-profile__bio {
  display: flex;
  width: 100%;
  height: 85rem;
  background-color: white;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-radius: 1rem; }

.my-profile__initials {
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  overflow: hidden; }

.my-profile__body {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 10rem; }

.my-profile__form {
  width: 80%;
  margin: 0 auto; }

.my-profile__form-group {
  margin-bottom: 1rem; }

.my-profile__form-group input,
.my-profile__form-group textarea,
.my-profile__form-group select {
  width: 100%;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 1rem;
  font-family: inherit; }

.my-profile__form-group label {
  text-align: left;
  font-size: 1.2rem;
  display: block;
  margin: 0 0 5px 3px; }

.my-profile__form-control {
  font-size: 1.6rem; }
  .my-profile__form-control:focus {
    outline: none; }

.my-profile__btn {
  display: inline-block;
  font-family: 'Metropolis-Light', sans-serif;
  font-size: 1.6rem;
  text-decoration: none;
  color: #f5f5f5;
  background-color: #f47521;
  padding: 1.5rem 4rem;
  display: inline-block;
  position: relative;
  border-radius: 8px;
  border: 2px solid #f5f5f5;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  transition: transform .4s, box-shadow .4s;
  position: relative;
  text-transform: uppercase;
  margin-top: 2rem;
  cursor: pointer; }
